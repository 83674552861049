import Vue from 'vue';
import VueI18n from 'vue-i18n';

import store from '@/store';
import messages from '@/../public/tenants/default/languages.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: store.state.lang,
  fallbackLocale: 'en',
  messages,
});
