<template>
  <canvas
    ref="canvas"
    class="RenderingEngine"
  />
</template>

<script lang="ts">
import JavascriptRenderingEngine from '@renoworks/javascript-rendering-engine';

export default {
  name: 'RenderingEngine',
  props: {
    sceneUrl: String,
    color: String,
  },
  data() {
    return {
      loading: false,

      engine: undefined,
    };
  },
  watch: {
    sceneUrl(url) {
      this.loadScene(url);
    },
    color(color) {
      this.drawScene(color);
    },
  },
  mounted() {
    this.engine = new JavascriptRenderingEngine(this.$refs.canvas);
    this.loadScene(this.sceneUrl);
  },
  methods: {
    drawScene(color = this.color) {
      if (this.engine) {
        this.engine.drawScene(color);
      }
    },
    async loadScene(url) {
      if (this.engine) {
        await this.engine.loadScene(url);
        this.drawScene(this.color);
      }
    },
  },
};
</script>

<style lang="styl">
.RenderingEngine {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
</style>
