<template>
  <v-layout
    column
    class="CollectionPicker"
  >
    <h3>{{ $t('colorPicker.filters.collection.title') }}</h3>

    <v-radio-group
      v-model="activeCollection"
      hide-details
      class="hidden-md-and-up"
    >
      <v-radio
        v-for="collection in collections"
        :key="collection.id"
        :value="collection.id"
        :label="$t_locale(collection, 'name')"
        color="primary"
      />
    </v-radio-group>
    <v-tabs
      v-model="activeCollectionIndex"
      color="transparent"
      show-arrows
      class="primary--text hidden-sm-and-down"
    >
      <v-tab
        v-for="collection in collections"
        :key="collection.id"
        @click="handleClick(collection.name)"
      >
        {{ $t_locale(collection, 'name') }}
      </v-tab>
      <v-layout
        v-if="!collections.length"
        align-center
      >
        <v-icon class="grey--text mr-2">
          block
        </v-icon>
        <em class="grey--text">{{ $t('colorPicker.filters.collection.noResults') }}</em>
      </v-layout>
    </v-tabs>
  </v-layout>
</template>

<script lang="ts">
export default {
  name: 'CollectionPicker',
  props: {
    value: String,
    collections: Array,
  },
  computed: {
    activeCollection: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    activeCollectionIndex: {
      get() {
        const index = this.collections.findIndex(c => c.id === this.value);
        return index === -1 ? null : index;
      },
      set(v: number) {
        this.$emit('input', this.collections[v || 0]?.id);
      },
    },
  },
  methods: {
    handleClick(value) {
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'Colour Collection Filter',
        eventLabel: value,
      });
    },
  },
};
</script>

<style lang="styl">
.CollectionPicker {
  min-width: 1px; // IE11 fix

  .v-tabs .v-tabs__bar > .v-tabs__icon {
    color: inherit !important; // skip all vuetify's nested selectors
  }
}
</style>
