<template>
  <div class="ColorDetails">
    <section v-if="complementaryColors.length">
      <v-divider class="mt-4" />
      <h4 class="py-2">
        {{ $t('colorPicker.complements.title') }}
      </h4>
      <ColorGrid
        color-group="Complementary Color"
        :colors="complementaryColors"
        dense
        @select="handleSelectColor"
      />
    </section>

    <section v-if="shadedColors.length">
      <v-divider class="mt-4" />
      <h4 class="py-2">
        {{ $t('colorPicker.shades.title') }}
      </h4>
      <ColorGrid
        color-group="Different Shades"
        :colors="shadedColors"
        dense
        @select="handleSelectColor"
      />
    </section>

    <section v-if="recentColors.length">
      <v-divider class="mt-4" />
      <h4 class="py-2">
        {{ $t('colorPicker.recents.title') }}
      </h4>
      <ColorGrid
        color-group="Recent Colours"
        :colors="recentColors"
        dense
        @select="handleSelectColor"
      />
    </section>

    <section v-if="favoriteColors.length">
      <v-divider class="mt-4" />
      <h4 class="py-2">
        {{ $t('colorPicker.favorites.title') }}
      </h4>
      <ColorGrid
        color-group="Pick Favourite Colour"
        :colors="favoriteColors"
        dense
        @select="handleSelectColor"
      />
    </section>
  </div>
</template>

<script lang="ts">
import {
  rgb2shades,
  rgb2complements,
  snap2closest,
} from '@renoworks/color';
import { Color, colorDecoder } from '@/main';
import ColorGrid from '@/components/ColorGrid.vue';

export default {
  name: 'ColorDetails',
  components: {
    ColorGrid,
  },
  props: {
    color: Object,
    excludedColors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    colors() {
      return this.$store.state.colors
        .filter(color => !this.excludedColors.find(c => c.code === color.code));
    },
    complementaryColors(): Color[] {
      if (this.color) {
        const complements = rgb2complements(colorDecoder(this.color));
        return this.findClosestColors(complements);
      }
      return [];
    },
    shadedColors(): Color[] {
      if (this.color) {
        const shades = rgb2shades(colorDecoder(this.color));
        return this.findClosestColors(shades);
      }
      return [];
    },
    recentColors(): Color[] {
      const visitedColorIDs = this.$store.state.visitedColorIDs;
      return this.colors
        // hydrate full color
        .filter(color => visitedColorIDs.includes(color.code))
        // exclude the current color
        .filter(color => this.color && color.code !== this.color.code)
        // only fill a single row
        .slice(0, 4)
        // sort in chronological order
        .sort((a, b) => visitedColorIDs.indexOf(a.code) - visitedColorIDs.indexOf(b.code));
    },
    favoriteColors(): Color[] {
      const favoriteColorIDs = this.$store.state.favoriteColorIDs;
      return this.colors
        // hydrate full color
        .filter(color => favoriteColorIDs.includes(color.code))
        // sort in chronological order
        .sort((a, b) => favoriteColorIDs.indexOf(a.code) - favoriteColorIDs.indexOf(b.code));
    },
  },
  methods: {
    findClosestColors(needles) {
      return needles.map(needle => snap2closest(needle, this.colors, {
        decode: colorDecoder,
      }))
        // remove duplicates and self
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter(({ hex }) => hex !== this.color.hex);
    },
    handleSelectColor(color) {
      this.$emit('select', color);
    },
  },
};
</script>

<style lang="styl">

</style>
