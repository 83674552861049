<template>
  <v-app>
    <template v-if="!$store.state.loading">
      <v-toolbar
        app
        flat
        color="white"
        :height="60"
        class="AppToolbar"
      >
        <v-menu
          v-if="enabledPages.length > 1 || $store.getters.hasFeature('localization')"
          offset-y
          bottom
          content-class="AppMenu"
          z-index="2"
        >
          <v-toolbar-side-icon
            slot="activator"
            class="hidden-md-and-up"
          />
          <v-list>
            <template v-if="enabledPages.length > 1">
              <v-list-tile
                v-for="page in enabledPages"
                :key="page.name"
                :to="page"
                ripple
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ page.meta.title() }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-divider v-if="enabledPages.length > 1 && $store.getters.hasFeature('localization')" />
            <v-list-tile
              v-if="$store.getters.hasFeature('localization')"
              @click.stop
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('language') }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <LanguagePicker />
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
          <v-divider class="accent--text" />
        </v-menu>

        <v-spacer class="hidden-md-and-up" />

        <v-toolbar-title
          class="mx-0"
          style="min-width: 100px;"
        >
          <router-link
            to="/"
            style="color: inherit; text-decoration: none; font-weight: bold;"
            @click="handleClick('Logo Click', 'Logo')"
          >
            <img
              v-if="$store.getters.hasFeature('logo')"
              :src="`/tenants/${$root.tenant}/assets/images/logo.png`"
              :alt="$t('title') || $t('company.name')"
              style="max-width: 100%; max-height: 35px; vertical-align: middle;"
            >
            <template v-else>
              {{ $t('title') || $t('company.name') }}
            </template>
          </router-link>
        </v-toolbar-title>

        <v-toolbar-items
          v-if="enabledPages.length > 1"
          class="hidden-sm-and-down layout align-center ml-4"
        >
          <router-link
            v-for="page in enabledPages"
            :key="page.name"
            :to="page"
            class="subheading px-3"
          >
            <span @click="handleClick('Change View', `Switch to ${page.meta.title()}`)">
              {{ page.meta.title() }}
            </span>
          </router-link>
        </v-toolbar-items>

        <v-spacer />

        <v-toolbar-items v-if="$store.getters.hasFeature('localization')">
          <LanguagePicker class="hidden-sm-and-down subheading mx-2" />
        </v-toolbar-items>

        <v-btn
          v-if="$store.getters.isEmbedded"
          flat
          class="AppClose"
          @click="handleClose"
        >
          <v-icon>close</v-icon>
        </v-btn>

        <v-divider class="accent--text" />
      </v-toolbar>

      <v-content>
        <router-view />
      </v-content>

      <v-footer
        v-if="!$store.getters.isEmbedded"
        app
        fixed
        height="50"
        class="AppFooter"
      >
        <v-divider class="accent--text" />
        <div :class="{'pt-5' : $vuetify.breakpoint.smAndDown }">
          <h5
            class="font-weight-regular px-3 mx-auto"
            v-html="disclaimerText"
          />
        </div>
      </v-footer>
    </template>
    <div
      v-else
      class="layout align-center"
    >
      <v-progress-circular
        indeterminate
        color="grey"
        size="64"
        class="flex ma-5"
      />
    </div>
  </v-app>
</template>

<script lang="ts">
import LanguagePicker from '@/components/LanguagePicker.vue';

export default {
  name: 'App',
  components: {
    LanguagePicker,
  },
  data() {
    return {
      pages: (this.$router as any).options.routes.filter(route => route.name && route.meta),
    };
  },
  computed: {
    enabledPages() {
      return this.pages.filter(page => this.$store.getters.hasFeature(page.name));
    },
    currentPageName: {
      get() {
        return this.$route.name;
      },
      set(name) {
        this.$router.push({ name });
      },
    },
    disclaimerText() {
      return this.$t('footer.disclaimer');
    },
  },
  created() {
    const { lang } = this.$route.query;
    if (lang) {
      this.$store.commit('setLang', lang);
    }

    this.$store.dispatch('loadTenant', this);
  },
  methods: {
    handleClose() {
      this.$store.dispatch('postMessage');
    },
    handleClick(action, label) {
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: action,
        eventLabel: label,
      });
    },
  },
};
</script>

<style lang="styl">
// all !importants below are to overrides Vuetify styles that get
// set via [style] or with !important themselves
.AppMenu {
  position: fixed !important; // why doesn't [fixed] work, I wonder?
  top: 56px !important;
  left: 0 !important;
  right: 0;
  max-width: none !important;
  border-radius: 0;
}
.AppToolbar {
  .v-toolbar__content {
    padding-right: 0 !important;

    .v-btn {
      color: inherit !important;
    }
    .v-divider {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
    }
  }
}
.AppMenu .LanguagePicker,
.AppToolbar .v-toolbar__items {
  a {
    display: flex;
    color: inherit;
    text-decoration: none;

    &:not(:last-child) {
      border-right: 1px solid currentColor !important;
    }
    &:hover,
    &:active,
    &.router-link-exact-active {
      text-decoration: underline !important;
    }
  }
}
.AppClose {
  min-width: 0 !important;
  height: auto !important;
  margin: 0 !important;
  align-self: stretch;
}
.AppFooter {
  flex-direction: column;
  align-items: stretch;

  > div {
    display: flex;
    flex: auto;
    align-items: center;
    overflow-x: auto;
  }
}

.v-overlay::before {
  background-color: #fff;
}
.v-divider.accent--text {
  border-color: currentColor !important;
  border-width: 1px;
}
.bordered {
  border: 1px solid #d8d8d8 !important;
}
</style>
