<template>
  <div class="LanguagePicker layout align-center">
    <a
      v-for="language in languages" 
      :key="language.id"
      class="text-uppercase px-3"
      :class="{ 'router-link-exact-active': selectedLanguage && selectedLanguage.id === language.id }"
      @click="handleSelectedLanguage(language)"
    >
      {{ language.langcode || language.id }}
    </a>
  </div>
</template>

<script lang="ts">
export default {
  name: 'LanguagePicker',
  computed: {
    languages() {
      const langs = Object.entries(this.$store.state.config?.features?.localization?.enabled)
        .filter(([k, v]) => v)
        .map(([k]) => ({ id: k, langcode: this.$t('langcode', k) || k }));
      return langs || [];
    },
    selectedLanguage: {
      get() {
        return this.languages.find(language => language.id === this.$store.state.lang);
      },
      set(language) {
        this.$store.commit('setLang', language.id);
      },
    },
  },
  created() {
    // restore lang on page load
    this.$store.commit('setLang', this.$store.state.lang, false);
  },
  methods: {
    handleSelectedLanguage(value) {
      this.selectedLanguage = value;
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'Language Selector',
        eventLabel: value.id,
      });
    },
  },
};
</script>
