<template>
  <v-card
    :ripple="ripple"
    :hover="hover"
    flat
    tile
    class="ColorCard"
    :class="{ ripple, hover }"
    @click="handleCardClick"
  >
    <v-responsive
      class="bordered"
      :style="{ background: color.hex }"
    >
      <v-btn
        v-if="$store.getters.hasFeature('favorites')"
        icon
        flat
        small
        color="primary"
        @click.stop="handleClick(color)"
      >
        <v-icon>{{ $store.getters.isFavoriteColor(color) ? 'star' : 'star_outline' }}</v-icon>
      </v-btn>
    </v-responsive>
    <v-card-text class="white py-1 px-0">
      <div class="ColorName text-truncate">
        <strong>{{ $t_locale(color, 'name') }}</strong>
      </div>
      <div class="ColorCode text-truncate">
        {{ color.code }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
export default {
  name: 'ColorCard',
  props: {
    color: Object,
    ripple: Boolean,
    hover: Boolean,
  },
  methods: {
    handleCardClick($event) {
      this.$emit('click', $event);
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'Visualize',
        eventLabel: `Switch to ${this.color.code} - ${this.color.name}`,
      });
    },
    handleClick(color) {
      this.$store.commit('toggleFavoriteColor', color);
      if (this.$store.getters.isFavoriteColor(color)) {
        this.$gtag.event({
          eventCategory: this.$route.meta.title(),
          eventAction: this.$route.meta.title() !== 'Colour finder'
            ? 'Paint Details Panel'
            : 'View Results',
          eventLabel: `Add Favourite - ${color.code} - ${color.name}`,
        });
      }
    },
  },
};
</script>

<style lang="styl">
.ColorCard {
  overflow: hidden; // truncate instead of expanding

  .v-responsive {
    .v-responsive__content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: 50px;

      .v-btn {
        background-color: #fff;
      }
    }
  }
  .v-card__text {
    display: flex;
    font-size: 16px;

    > *:first-child {
      flex-grow: 1;
    }
  }

  &.ripple {
    cursor: pointer;
  }
}
</style>
