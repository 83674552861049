<template>
  <v-navigation-drawer
    v-model="isActive"
    fixed
    temporary
    right
    disable-route-watcher
    :hide-overlay="hideOverlay"
    width="400"
    class="ColorDetail"
  >
    <v-card flat>
      <v-card-title class="layout pt-1 pl-4 pb-0 pr-1">
        <h2 class="title flex">
          {{ $t('colorPicker.details.title') }}
        </h2>
        <v-btn
          fab
          flat
          color="primary"
          class="ma-0"
          @click="handleSelectColor(null)"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="accent--text mx-4" />

      <v-card-text
        v-if="color"
        class="pa-0"
      >
        <div class="pa-4">
          <section
            v-if="!noPreview"
            class="mb-3 scene-preview"
          >
            <v-layout
              v-if="$store.state.scenes.length > 1"
              justify-end
            >
              <ScenePicker attach=".ColorDetail .v-card__text" />
            </v-layout>
            <RenderingEngine
              :scene-url="`${selectedScene}/project.xml`"
              :color="color && color.hex"
            />

            <v-btn
              v-if="$store.getters.hasFeature('fullscreen')"
              class="fullscreen-btn hidden-sm-and-down"
              @click="fullscreenOpen = !fullscreenOpen"
            >
              <v-icon>{{ fullscreenOpen ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
            </v-btn>
            <v-dialog
              v-model="fullscreenOpen"
              scrollable
            >
              <v-card tile>
                <v-card-title class="layout pt-1 pl-4 pb-0 pr-1">
                  <h2 class="title flex">
                    {{ $t('colorPicker.fullscreen.title') }}
                  </h2>
                  <v-btn
                    fab
                    flat
                    color="primary"
                    class="ma-0"
                    @click="fullscreenOpen = false;"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider class="accent--text mx-4" />

                <v-card-text class="px-4 pb-4">
                  <RenderingEngine
                    :scene-url="`${selectedScene}/project.xml`"
                    :color="color && color.hex"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </section>

          <section>
            <ColorList :colors="[color]" />
          </section>

          <ColorDetails
            :color="color"
            @select="handleSelectColor"
          />
        </div>
      </v-card-text>

      <v-card-actions
        v-if="$store.getters.isEmbedded"
        class="pa-0"
      >
        <v-divider class="accent--text" />
        <v-btn
          color="primary"
          class="mx-4 my-3"
          @click="handleApplyColor"
        >
          {{ $t('colorPicker.details.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
import ScenePicker from '@/components/ScenePicker.vue';
import RenderingEngine from '@/components/RenderingEngine.vue';
import ColorList from '@/components/ColorList.vue';
import ColorDetails from '@/components/ColorDetails.vue';

export default {
  name: 'ColorDetail',
  components: {
    ScenePicker,
    RenderingEngine,
    ColorList,
    ColorDetails,
  },
  props: {
    value: Boolean,
    color: Object,
    noPreview: Boolean,
    hideOverlay: Boolean,
  },
  data() {
    return {
      fullscreenOpen: false,
    };
  },
  computed: {
    isActive: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },

    selectedScene() {
      return this.$store.state.selectedScene;
    },
  },
  watch: {
    isActive(isActive) {
      if (isActive) {
        // manually add overflow class to resolve duplicate scrollbar on IE11
        // @HACK: poached from Vuetify source @ https://bit.ly/2X8uyT0
        document.documentElement.classList.add('overflow-y-hidden');
      }
    },
  },
  methods: {
    handleSelectColor(color) {
      this.$emit('select', color);
    },
    handleApplyColor() {
      this.$store.dispatch('postMessage', {
        color: this.color,
      });
    },
  },
};
</script>

<style lang="styl">
.ColorDetail {
  // fix header to top, and overflow only contents
  overflow: hidden;

  .scene-preview {
    position: relative;

    .fullscreen-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      min-width: 36px;
      padding: 0;
    }
  }

  > .v-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .v-card__title {
      flex: none;
    }
    > .v-card__text {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      position: relative; // to base attach-ed v-menus on
    }
    > .v-card__actions {
      flex-direction: column;
      align-items: stretch;
      flex-shrink: 0;
    }
  }

  .ColorList {
    pointer-events: none;

    .v-btn {
      pointer-events: auto;
    }
  }
}
</style>
