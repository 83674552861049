<template>
  <v-expansion-panel
    v-model="expandedIndex"
    class="ColorList"
    :class="{ dense, stacked, ripple }"
  >
    <v-expansion-panel-content
      v-for="(color, index) in colors"
      :key="index"
      hide-actions
      :ripple="ripple"
    >
      <ColorCard
        slot="header"
        :color="color"
        @click="$emit('select', color)"
      />
      <slot :color="color" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import ColorCard from '@/components/ColorCard.vue';

export default {
  name: 'ColorList',
  components: {
    ColorCard,
  },
  props: {
    value: Number,
    colors: Array,
    dense: Boolean,
    stacked: Boolean,
    ripple: Boolean,
  },
  computed: {
    expandedIndex: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="styl">
@import '~vuetify/src/stylus/bootstrap';

dense() {
  grid-gap: 16px;
}
stacked() {
  .v-card {
    .v-card__text {
      flex-direction: column;
    }
  }
}

.ColorList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  box-shadow: none;

  .v-expansion-panel__header {
    padding: 0;
  }

  // IE11 fixes (doesn't support CSS grid)
  _:-ms-fullscreen, :root & {
    display: flex;
    flex-wrap: wrap;
    margin: -(32px / 2);

    > * {
      width: 100%;
      margin: (32px / 2);
    }
  }

  &.dense {
    dense();
  }
  &.stacked {
    stacked();
  }
  @media $display-breakpoints.xs-only {
    dense();
  }
}
</style>
