<template>
  <Drop
    class="FileDropper"
    :class="{ dragover }"
    drop-allowed="copy"
    @dragenter="dragover = true"
    @dragover="dragover = true"
    @dragleave="dragover = false"
    @dragend="dragover = false"
    @drop="handleDropFile"
  >
    <slot />

    <label>
      <input
        type="file"
        :accept="accept"
        @change="handleSelectFile"
      >
    </label>
  </Drop>
</template>

<script lang="ts">
import { Drop } from 'vue-drag-drop';

export default {
  name: 'FileDropper',
  components: {
    Drop,
  },
  props: {
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  data() {
    return {
      dragover: false,
    };
  },
  methods: {
    handleSelect(files) {
      const accept = new RegExp(this.accept.replace('*', '.*'), 'i');
      const filtered = Array.from(files).filter((file: File) => accept.test(file.type));
      if (filtered.length) {
        this.$emit('select', filtered);
      }
    },
    handleSelectFile({ target }) {
      this.handleSelect(target.files);
    },
    handleDropFile(_, { dataTransfer }) {
      this.dragover = false;
      this.handleSelect(dataTransfer.files);
    },
  },
};
</script>

<style lang="styl">
.FileDropper {
  position: relative;
  border: dashed 4px transparent;
  cursor: pointer;

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: inherit;

    input {
      display: none;
    }
  }

  &:hover,
  &.dragover {
    border-color: currentColor;
  }
}
</style>
