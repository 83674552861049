<template>
  <div class="ColorFamilyFilters">
    <header class="layout align-center">
      <h3 class="flex">
        {{ $t('colorPicker.filters.colorFamily.title') }}
      </h3>
      <a
        v-if="value"
        class="mx-2"
        @click="handleChange(null)"
      >
        {{ $t('colorPicker.filters.colorFamily.clear') }}
      </a>
    </header>
    <v-layout
      wrap
      class="color-family-filters mt-3"
    >
      <v-btn
        v-for="colorFamily in colorFamilies"
        :key="colorFamily.color"
        fab
        small
        depressed
        :color="colorFamily.color"
        :dark="!colorFamily.dark"
        :class="{ bordered: colorFamily.dark }"
        @click="handleChange(colorFamily.color)"
      >
        <v-icon
          v-if="value && value === colorFamily.color"
        >
          brightness_1
        </v-icon>
      </v-btn>
    </v-layout>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ColorFamilyFilters',
  props: {
    value: String,
    colorFamilies: Array,
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value);
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'Colour Family Filter',
        eventLabel: value || 'clear',
      });
    },
  },
};
</script>

<style lang="styl">
.ColorFamilyFilters {
  .v-btn {
    margin: 6px;

    @media (min-width: 600px) {
      width: 32px;
      height: 32px;
    }
    @media (min-width: 960px) {
      width: 24px;
      height: 24px;
    }
    &.white {
      border: 1px solid black !important;

      i::after {
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        border: 1px solid black;
      }
    }
  }
  @media (min-width: 600px) {
    justify-content: space-between;
  }
}
</style>
