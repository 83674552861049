<template>
  <v-menu
    v-model="isOpen"
    full-width
    bottom
    left
    offset-y
    :attach="attach"
    max-width="352"
    max-height="400"
    content-class="ScenePicker"
  >
    <a
      slot="activator"
      class="d-flex mr-0 mb-2"
    >
      <h4 class="layout align-center">
        <v-icon
          color="primary"
          class="mr-1"
          style="transition-duration: 0s;"
          :style="{ opacity: isOpen ? 0 : null }"
        >
          add
        </v-icon>
        {{ $t(`colorPicker.details.scenePicker.toggle.${isOpen ? 'off': 'on'}`) }}
      </h4>
    </a>
    <v-card
      tile
      flat
      class="mb-2"
    >
      <v-layout wrap>
        <v-flex
          v-for="scene in scenes"
          :key="scene"
          xs6
          class="pa-2"
        >
          <v-card
            tile
            flat
            hover
            @click="handleSelectScene(scene)"
          >
            <v-responsive>
              <img
                :src="`${scene}/sample.jpg`"
                style="width: 100%; vertical-align: middle;"
              >
            </v-responsive>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
    <v-divider class="accent--text" />
  </v-menu>
</template>

<script lang="ts">
export default {
  name: 'ScenePicker',
  props: {
    attach: true,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    scenes() {
      return this.$store.state.scenes;
    },
  },
  methods: {
    handleSelectScene(scene) {
      this.$store.commit('selectScene', scene);
      this.$emit('select', scene);
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'Load Preview Image',
        eventLabel: scene,
      });
    },
  },
};
</script>

<style lang="styl" scoped>
.ScenePicker {
  display: flex;
  flex-direction: column;
  background: white;
  margin-right: 12px;

  > .v-card {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
