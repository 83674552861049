import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/i18n';
import ColorPicker from './views/ColorPicker.vue';
import ColorDetector from './views/ColorDetector.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/colors/:colorID?',
      name: 'color-picker',
      component: ColorPicker,
      props: true,
      meta: {
        title: () => i18n.t('colorPicker.title'),
      },
    },
    {
      path: '/detect/:colorIDs?/:colorIndex?',
      name: 'color-detector',
      component: ColorDetector,
      props: true,
      meta: {
        title: () => i18n.t('colorDetector.title'),
      },
    },
    {
      path: '/',
      redirect: '/colors',
    },
  ],
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title();
  }
});

export default router;
