<template>
  <v-layout column>
    <aside class="FiltersToolbar">
      <v-layout class="black--text">
        <v-flex
          v-if="$store.getters.hasFeature(['color-picker', 'filter-search'])"
          class="pa-4"
          style="flex-shrink: 0; min-width: 290px;"
        >
          <h3 class="hidden-sm-and-down">{{ $t('colorPicker.filters.search.title') }}</h3>
          <v-text-field
            type="search"
            v-model="searchFilter"
            :label="$t('colorPicker.filters.search.placeholder')"
            single-line
            hide-details
            :append-icon="searchFilter ? 'clear' : 'search'"
            @click:append="searchFilter = ''"
            @blur="handleSearch(searchFilter)"
          />
        </v-flex>
        <ColorFamilyPicker
          v-if="$store.getters.hasFeature(['color-picker', 'filter-family'])"
          v-model="colorFamilyFilter"
          :colorFamilies="colorFamilies"
          class="hidden-sm-and-down flex pa-4"
          style="flex-shrink: 0;"
        />
        <CollectionPicker
          v-if="$store.getters.hasFeature(['color-picker', 'filter-collection'])"
          v-model="collectionFilter"
          :collections="collections"
          class="hidden-sm-and-down pa-4"
        />

        <p class="hidden-md-and-up mx-4 mb-2">{{ $t('colorPicker.filters.search.mobileDescription') }}</p>
        <v-btn
          :outline="!(colorFamilyFilter || collectionFilter)"
          color="primary"
          class="hidden-md-and-up mt-0 mb-3 mx-4"
          @click="filtersOpen = true"
        >
          {{ $t(`colorPicker.filters.dialog.${colorFamilyFilter || collectionFilter ? 'active' : 'open'}`) }}
        </v-btn>
        <v-dialog v-model="filtersOpen" fullscreen scrollable transition="dialog-top-transition">
          <v-card tile>
            <v-card-title class="layout pt-1 pl-4 pb-0 pr-1">
              <h2 class="title flex">{{ $t('colorPicker.filters.title') }}</h2>
              <v-btn
                fab
                flat
                color="primary"
                class="ma-0"
                @click="filtersOpen = false;"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="accent--text mx-4" />

            <v-card-text>
              <ColorFamilyPicker
                v-model="colorFamilyFilter"
                :colorFamilies="colorFamilies"
                class="mb-4"
              />
              <CollectionPicker
                v-model="collectionFilter"
                :collections="collections"
              />
            </v-card-text>

            <v-card-actions class="px-3 pb-3">
              <v-btn block color="primary" outline class="ma-2" :disabled="!(colorFamilyFilter || collectionFilter)" @click="colorFamilyFilter = null; collectionFilter = null;">
                {{ $t('colorPicker.filters.dialog.reset') }}
              </v-btn>
              <v-btn block color="primary" class="ma-2" @click="filtersOpen = false">
                {{ $t('colorPicker.filters.dialog.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </aside>

    <div class="px-4">
      <h4 class="font-weight-regular mt-3 py-2">{{ $t('colorPicker.colors.title') }}</h4>
      <v-divider />
    </div>
    <ColorGrid
      ref="colors"
      :colors="filteredColorsPaginated"
      class="touching pa-4"
      @select="handleSelectColor"
    />
    <div
      v-infinite-scroll="handleInfiniteScroll"
      infinite-scroll-distance="300"
      class="layout align-center"
      style="overflow: hidden;"
    >
      <v-progress-circular
        v-if="filteredColorsPaginated.length < filteredColorsSorted.length"
        indeterminate
        color="grey"
        size="64"
        class="flex ma-5"
      />
    </div>

    <ColorDetail
      v-model="isSelectedColorActive"
      :color="selectedColor"
      @select="handleSelectColor"
    />
  </v-layout>
</template>

<script lang="ts">
import infiniteScroll from 'vue-infinite-scroll';
import Fuse from 'fuse.js';
import { Family, Category, Color } from '@/main';
import ColorFamilyPicker from '@/components/ColorFamilyPicker.vue';
import CollectionPicker from '@/components/CollectionPicker.vue';
import ColorGrid from '@/components/ColorGrid.vue';
import ColorDetail from '@/components/ColorDetail.vue';

export default {
  name: 'ColorPicker',
  props: {
    colorID: String,
  },
  data() {
    return {
      colorFamilies: <Family[]> [
        {
          color: 'red',
          hsvRanges: [[340, 10], [7, 255], [0, 100]],
        },
        {
          color: 'orange',
          hsvRanges: [[10, 35], [7, 255], [0, 100]],
        },
        {
          color: 'yellow',
          hsvRanges: [[35, 55], [7, 255], [0, 100]],
        },
        {
          color: 'green',
          hsvRanges: [[55, 175], [7, 255], [0, 100]],
        },
        {
          color: 'blue',
          hsvRanges: [[175, 235], [7, 255], [0, 100]],
        },
        {
          color: 'purple',
          hsvRanges: [[235, 340], [7, 255], [0, 100]],
        },
        {
          color: 'grey',
          hsvRanges: [[0, 360], [0, 7], [30, 100]],
        },
        {
          color: 'black',
          hsvRanges: [[0, 360], [0, 45], [0, 30]],
        },
        {
          color: 'white',
          hsvRanges: [[0, 360], [0, 7], [90, 100]],
        },
      ],
      filteredColorsPage: <number> 0,
      colorGridColumnCount: <number> 0,

      filtersOpen: <boolean> false,
      searchFilter: <string> undefined,
      colorFamilyFilter: <string> undefined,
      collectionFilter: <string> null,

      isSelectedColorActive: false,
    };
  },
  watch: {
    filteredColors() {
      this.filteredColorsPage = 1;
    },
    $route: {
      handler() {
        this.isSelectedColorActive = !!this.colorID;
      },
      immediate: true,
    },
    isSelectedColorActive(v) {
      if (!v) {
        this.handleSelectColor(null);
      }
    },
    colorFamilyFilter(v) {
      if (v) {
        this.collectionFilter = null;
      }
    },
    collectionFilter(v) {
      if (v) {
        this.colorFamilyFilter = null;
      }
    },
  },
  computed: {
    collections(): Category[] {
      return this.$store.state.collections;
    },
    colors(): Color[] {
      return this.$store.state.colors;
    },
    selectedColor() {
      return this.colors.find(c => c.code === this.colorID);
    },

    filteredColors(): Color[] {
      let colors = this.colors
        .filter((color: Color) => {
          if (this.collectionFilter) {
            if (!color.filters.includes(this.collectionFilter)) {
              return false;
            }
          }
          if (this.colorFamilyFilter) {
            const colorFamily = this.colorFamilies.find(f => f.color === this.colorFamilyFilter);
            if (colorFamily) {
              const [hh, ss, vv] = colorFamily.hsvRanges;
              const [h, s, v] = color.hsv;
              // wrap around the color 'wheel', if necessary
              const hueInRange = hh[0] > hh[1] ? h >= hh[0] || h < hh[1] : h >= hh[0] && h < hh[1];
              if (!(hueInRange && s >= ss[0] && s <= ss[1] && v >= vv[0] && v <= vv[1])) {
                return false;
              }
            }
          }
          return true;
        });

      const duplicateColors = colors.length
        && colors.filter((v, i, a) => a.findIndex(({ code }) => v.code === code) !== i);
      if (duplicateColors?.length) {
        // prevent search from breaking by destructively removing duplicates
        // NB: proper fix is to remove dupes from source color list
        console.warn(
          'Colors with duplicate "code" detected:',
          duplicateColors.map(({ code }) => code),
        );
        colors = colors.filter((v, i, a) => a.findIndex(({ code }) => v.code === code) === i);
      }

      if (this.searchFilter) {
        const fuse = new Fuse(colors, {
          keys: ['name', 'name_fr', 'code'],
          threshold: 0.15,
        });
        return <Color[]> fuse.search(this.searchFilter);
      }

      return colors;
    },
    filteredColorsSorted(): Color[] {
      // first sort by overall hue
      const colors = this.filteredColors.sort((a: Color, b: Color) => {
        if (a.hsv[0] < b.hsv[0]) return -1;
        if (a.hsv[0] > b.hsv[0]) return 1;
        return 0;
      });

      if (this.colorGridColumnCount) {
        const sorted = [];
        for (let i = 0; i < colors.length; i += this.colorGridColumnCount) {
          const row = colors.slice(i, i + this.colorGridColumnCount);

          // then sort each row by difference between saturation and lightness
          row.sort((a: Color, b: Color) => {
            if (a.hsv[1] - a.hsv[2] < b.hsv[1] - b.hsv[2]) return -1;
            if (a.hsv[1] - a.hsv[2] > b.hsv[1] - b.hsv[2]) return 1;
            return 0;
          });

          sorted.push(...row);
        }
        return sorted;
      }
      return colors;
    },
    filteredColorsPaginated(): Color[] {
      return this.filteredColorsSorted.slice(0, this.filteredColorsPage * 100);
    },
  },
  methods: {
    handleInfiniteScroll() {
      this.filteredColorsPage += 1;
    },
    handleSelectColor(color) {
      if (color) {
        this.$store.commit('visitColor', color);
      }

      this.$router.push({
        params: {
          colorID: color ? color.code : undefined,
        },
      });
    },
    handleSearch(value) {
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'Search Field',
        eventLabel: value,
      });
    },
  },
  created() {
    const sortColorGrid = async () => {
      if (this.$refs.colors && this.$refs.colors.$el) {
        await this.$nextTick();

        let offsetTop;
        const children = <HTMLElement[]> Array.from(this.$refs.colors.$el.childNodes);
        this.colorGridColumnCount = children.findIndex((el) => {
          if (offsetTop === undefined) {
            offsetTop = el.offsetTop;
            return false;
          }
          return offsetTop !== el.offsetTop;
        });
      }
    };
    window.addEventListener('resize', sortColorGrid);

    sortColorGrid();
  },
  directives: {
    infiniteScroll,
  },
  components: {
    ColorFamilyPicker,
    CollectionPicker,
    ColorGrid,
    ColorDetail,
  },
};
</script>

<style lang="styl">
.FiltersToolbar {
  background-color: white;

  @media (max-width: 959px) {
    position: sticky;
    top: 56px; // @HACK: how to get real header height?
    z-index: 1;

    .layout {
      flex-direction: column;
      flex-wrap: wrap;
    }
    .v-text-field--single-line {
      padding-top: 0;
      margin-top: 0;
    }
    .v-btn.v-btn--outline {
      border-width: 2px;
    }
    + * h4 {
      margin-top: 0 !important; // override Vuetify's !important
    }
  }
}
</style>
