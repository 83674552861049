import Vue from 'vue';
import Vuex, { Commit } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { get } from 'lodash';
import deepmerge from 'deepmerge';
import axios from 'axios';
import rgb2hsv from 'pure-color/convert/rgb2hsv';
import { hex2rgb } from '@renoworks/color';

import router from '@/router';
import i18n from '@/i18n';
import defaultConfig from '@/../public/tenants/default/config.json';
import {
  IColorsConfig,
  ILangCode,
  ILanguageConfig,
  IState,
  IStateColor,
  IStateConfig,
} from '@/types';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence();

type ILodashGetPathArg = Parameters<typeof get>[1];
interface IGetters {
  isFavoriteColor(color?: IStateColor): boolean;
  hasFeature(feature: ILodashGetPathArg): boolean;
  isEmbedded: boolean;
}

async function loadConfig(tenant: string, commit: Commit) {
  const { data: config } = await axios(`/tenants/${tenant}/config.json`);
  const newConfig = deepmerge<IStateConfig>(
    // @ts-ignore
    defaultConfig,
    config,
    {
      arrayMerge: (_, arr) => arr,
    },
  );
  commit('setConfig', newConfig);
  return newConfig;
}

function isDefaultConfigOverridden(config: IStateConfig, path: ILodashGetPathArg) {
  return get(config, path) !== get(defaultConfig, path);
}

async function loadLang(tenant: string, config: IStateConfig, state: IState, commit: Commit) {
  const isDefaultOverridden = isDefaultConfigOverridden(config, 'features.localization.default');
  const { langIsUserSelected } = state;
  if (isDefaultOverridden && !langIsUserSelected) {
    commit('setLang', config.features?.localization?.default);
  }
  const { data = {} }: { data: ILanguageConfig } = await axios(`/tenants/${tenant}/languages.json`);
  Object.entries(data).forEach(([locale, messages]) => {
    i18n.mergeLocaleMessage(locale, messages);
  });
}

async function loadColors(tenant: string, state: IState, getters: IGetters) {
  const { data }: { data: IColorsConfig } = await axios(`/tenants/${tenant}/colors.json`);

  let collections = data.categories || [];

  if (getters.hasFeature(['color-picker', 'filter-collection', 'all'])) {
    collections = [
      {
        name: i18n.t('colorPicker.filters.collection.all', 'en') as string,
        name_fr: i18n.t('colorPicker.filters.collection.all', 'fr') as string,
      },
      ...collections,
    ];
  }

  state.collections = collections;

  state.colors = data.colors.map((color) => {
    // add calculated values to save re-parsing
    const hex = color.rgb;
    const rgb = hex2rgb(hex);
    return {
      ...color,
      hex,
      rgb,
      hsv: rgb2hsv(rgb),
    };
  });
}

async function loadStyles(tenant: string) {
  return new Promise((resolve) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `/tenants/${tenant}/tenant.css`;
    link.onload = resolve;
    link.onerror = resolve;
    document.body.appendChild(link);
  });
}

class Deferred {
  promise: Promise<any>;
  resolve: Function;
  reject: Function;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export const analyticsGA4ID = new Deferred();
function loadAnalyticsGA4(config: IStateConfig) {
  if (config.analytics) {
    const code = config.analytics.ga4_value;
    if (code) {
      analyticsGA4ID.resolve(code);
    }
  }
}

function loadTheme(config: IStateConfig, app: any) {
  Object.assign(app.$vuetify.theme, config.theme);
}

function loadScenes(tenant: string, config: IStateConfig, commit: Commit) {
  const scenes = config.projects?.map(guid => `/tenants/${tenant}/projects/${guid}`) || [];
  commit('setScenes', scenes);
  if (scenes.length) {
    commit('selectScene', scenes[0]);
  }
}

export default new Vuex.Store<IState>({
  state: {
    lang: 'en',
    langIsUserSelected: false,
    config: (defaultConfig as unknown) as IState['config'],

    loading: false,
    collections: [],
    colors: [],
    visitedColorIDs: [],
    favoriteColorIDs: [],

    scenes: [],
    selectedScene: undefined,
  },
  getters: {
    isFavoriteColor: state => (color?: IStateColor) =>
      !!(color && state.favoriteColorIDs.find(code => code === color.code)),
    isEmbedded: () => {
      try {
        return window.location.search.toLowerCase().includes('embedded')
          || window.self !== window.top;
      } catch (err) {
        return false;
      }
    },
    hasFeature: state => (feature: ILodashGetPathArg) => {
      return get(state.config.features, feature);
    },
  },
  mutations: {
    setLang(state, lang: ILangCode, isUserSelected = true) {
      i18n.locale = lang;
      state.lang = lang;
      state.langIsUserSelected = isUserSelected;

      // update page title on lang change
      if (router.currentRoute.meta?.title) {
        document.title = router.currentRoute.meta.title();
      }
    },
    setConfig(state, config: IState['config']) {
      state.config = config;
    },
    visitColor(state, color?: IStateColor) {
      if (!color) return;

      state.visitedColorIDs = [color.code, ...state.visitedColorIDs]
        .filter((v, i, a) => a.indexOf(v) === i) // remove duplicates
        .slice(0, 5); // prevent memory leaking
    },
    toggleFavoriteColor(state, color?: IStateColor) {
      if (!color) return;

      state.favoriteColorIDs = state.favoriteColorIDs.includes(color.code)
        ? state.favoriteColorIDs.filter(code => code !== color.code) // remove
        : [...state.favoriteColorIDs, color.code]; // append
    },
    setScenes(state, scenes: IState['scenes']) {
      state.scenes = scenes;
    },
    selectScene(state, scene: IState['selectedScene']) {
      state.selectedScene = scene;
    },
  },
  actions: {
    async loadTenant({ state, getters, commit }, app) {
      state.loading = true;

      try {
        const { tenant } = app.$root;
        const config = await loadConfig(tenant, commit);
        await Promise.all([
          loadLang(tenant, config, state, commit),
          loadColors(tenant, state, getters),
          loadStyles(tenant),
          loadAnalyticsGA4(config),
          loadTheme(config, app),
          loadScenes(tenant, config, commit),
        ]);
      } catch (err) {
        console.warn(err);
      }

      state.loading = false;
    },

    postMessage({ getters }, data = {}) {
      if (getters.isEmbedded && window.parent) {
        window.parent.postMessage(JSON.stringify(data), '*');
      }
    },
  },
  plugins: [
    vuexLocal.plugin,
  ],
});
