import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=52a7125b"
import script from "./ColorPicker.vue?vue&type=script&lang=ts"
export * from "./ColorPicker.vue?vue&type=script&lang=ts"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=52a7125b&prod&lang=styl"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports