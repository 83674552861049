<template>
  <v-layout column class="ColorDetector py-3">
    <div v-if="!paletteColors.length" class="upload px-4">
      <header class="my-3 pb-3">
        <h2 class="title">{{ $t('colorDetector.upload.title') }}</h2>
        <h3>{{ $t('colorDetector.upload.description') }}</h3>
      </header>

      <FileDropper v-if="!image" class="primary--text mt-3" @select="handleSelectImage">
        <figure class="layout justify-center align-center grey lighten-3 mb-3">
          <img :src="`/tenants/${$root.tenant}/assets/images/upload.jpg`" role="presentation" />
        </figure>

        <footer class="black--text subheading pb-3">
          <span>{{ $t('colorDetector.upload.input.drag') }}</span>&nbsp;
          <a>{{ $t('colorDetector.upload.input.file') }}</a>
        </footer>
      </FileDropper>
      <div v-else>
        <ColorList
          :colors="detectedColors"
          stacked
          class="PaletteColors mb-3"
          @select="handleSelectDetectedColor"
        />

        <figure class="layout justify-center align-center grey lighten-3">
          <img :src="image" role="presentation" />
        </figure>

        <footer class="layout justify-space-between align-center my-3">
          <v-btn color="accent" @click="handleUploadDifferent">
            {{ $t('colorDetector.upload.previous') }}
          </v-btn>
          <v-btn
            v-if="!$store.getters.isEmbedded"
            color="primary mx-0"
            @click="handleSelectDetectedColor()"
          >
            {{ $t('colorDetector.upload.next') }}
          </v-btn>
        </footer>
      </div>
    </div>
    <div v-else class="visualize px-4">
      <header class="my-3">
        <h2 class="title">{{ $t('colorDetector.visualize.title') }}</h2>
        <h3>{{ $t('colorDetector.visualize.description') }}</h3>
      </header>

      <div class="layout">
        <figure class="layout justify-center align-start grey lighten-3 mr-4">
          <RenderingEngine
            :scene-url="`${selectedScene}/project.xml`"
            :color="selectedColor && selectedColor.hex"
          />
        </figure>

        <ColorList v-model="selectedColorIndex" :colors="paletteColors">
          <template v-slot="{ color }">
            <ColorDetails
              :color="color"
              @select="handleReplaceColor"
            />
          </template>
        </ColorList>
      </div>

      <v-divider class="accent--text my-3" />

      <footer>
        <v-btn
          color="accent"
          :to="{ name: 'color-detector', params: { colorIDs: null } }"
          @click="image = null"
        >
          {{ $t('colorDetector.visualize.previous') }}
        </v-btn>
      </footer>
    </div>

    <ColorDetail
      v-if="$store.getters.isEmbedded"
      v-model="isPreviewedColorActive"
      :color="previewedColor"
      @select="handleSelectDetectedColor"
    />
  </v-layout>
</template>

<script lang="ts">
import { Color, colorDecoder } from '@/main';
import ColorList from '@/components/ColorList.vue';
import ColorDetail from '@/components/ColorDetail.vue';
import ColorDetails from '@/components/ColorDetails.vue';
import RenderingEngine from '@/components/RenderingEngine.vue';
import FileDropper from '@/components/FileDropper.vue';
import ColorThief from '@mariotacke/color-thief';
import { snap2closest } from '@renoworks/color';

const colorThief = new ColorThief();

export default {
  name: 'ColorDetector',
  props: {
    colorIDs: String,
    colorIndex: [Number, String],
  },
  data() {
    return {
      image: undefined,
      detectedColors: <Color[]> [],
      previewedColor: <Color> undefined,
      isPreviewedColorActive: false,
    };
  },
  computed: {
    colors(): Color[] {
      return this.$store.state.colors;
    },
    selectedScene(): string {
      return this.$store.state.selectedScene;
    },
    paletteColors(): Color[] {
      if (this.colorIDs) {
        return this.colorIDs.split(',')
          // remove empties
          .filter(v => v)
          .map(code => this.colors.find(c => c.code === code));
      }
      return [];
    },
    selectedColor(): Color {
      return this.paletteColors[this.selectedColorIndex];
    },
    selectedColorIndex: {
      get() {
        return Number.parseInt(this.colorIndex, 10);
      },
      set(colorIndex) {
        this.handleSelectColor(colorIndex);
      },
    },
  },
  methods: {
    async detectColors(src = this.image) {
      return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.onload = () => {
          const detectedColors = colorThief.getPalette(img);

          const paletteColors = detectedColors.map(needle => snap2closest(needle, this.colors, {
            decode: colorDecoder,
          }))
            .filter((v, i, a) => a.findIndex(c => c.code === v.code) === i)
            .slice(0, 5);

          resolve(paletteColors);
        };
        img.onerror = reject;
        img.src = src;
      });
    },
    async handleSelectImage(files: File[]) {
      if (!files.length) return;

      const file = files[0];
      this.image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
          this.$gtag.event({
            eventCategory: this.$route.meta.title(),
            eventAction: 'Upload Image',
            eventLabel: 'Upload Image',
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      this.detectedColors = await this.detectColors();
    },
    handleSelectDetectedColor(color) {
      const colorIDs = this.detectedColors.filter(c => c).map(c => c.code);
      const colorIndex = color && colorIDs.findIndex(code => code === color.code);

      this.$store.commit('visitColor', color);

      if (this.$store.getters.isEmbedded) {
        this.previewedColor = color;
        this.isPreviewedColorActive = !!color;
      } else {
        this.$router.push({
          params: {
            colorIDs: colorIDs.join(','),
            colorIndex,
          },
        });
      }
    },
    handleSelectColor(colorIndex, replaceColor = undefined) {
      const color = this.paletteColors[colorIndex];
      if (color) {
        const colorIDs = this.paletteColors
          .map((c, i) => (replaceColor && i === colorIndex  ? replaceColor : c).code);

        this.$store.commit('visitColor', color);

        this.$router.push({
          params: {
            colorIDs: colorIDs.join(','),
            colorIndex,
          },
        });
      }
    },
    handleReplaceColor(color) {
      if (color) {
        this.handleSelectColor(this.selectedColorIndex, color);
      }
    },
    handleUploadDifferent(image) {
      this.image = null;
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: 'View Results',
        eventLabel: 'Upload Different Image',
      });
    },
  },
  components: {
    ColorList,
    ColorDetail,
    ColorDetails,
    RenderingEngine,
    FileDropper,
  },
};
</script>

<style lang="styl">
@import '~vuetify/src/stylus/bootstrap';

$breakpoints := {
  mobile: "only screen and (max-width: 767px)",
  desktop: "only screen and (min-width: 768px)",
}

.ColorDetector {
  footer {
    a:not(.v-btn) {
      text-decoration: underline;
      white-space: nowrap;
    }
  }
  figure {
    img,
    canvas {
      min-width: 1px; // for IE11
      max-width: 100%;
      max-height: 50vh;
    }
  }

  .upload {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%; // for IE11
    text-align: center;

    .FileDropper {
      align-self: center;
      max-width: 880px;
      min-height: 1px; // for IE11
    }

    .PaletteColors {
      grid-template-columns: repeat(5, 1fr);
      text-align: left;

      _:-ms-fullscreen, :root & { // for IE11 only
        .v-card {
          width: calc(20% - 32px);
        }
      }
    }
  }

  .visualize {
    .RenderingEngine {
      width: auto;
      height: auto;
      max-height: 67vh;
    }
  }

  @media $breakpoints.mobile {
    .upload {
      .ColorList {
        .v-card {
          .v-card__text,
          .v-btn {
            display: none;
          }
        }
      }

      footer {
        flex-direction: column;

        a {
          display: flex;
          justify-content: center;
          margin: 8px;
        }
        .v-btn {
          width: 100%;
        }
      }
    }
    .visualize {
      .ColorList {
        width: 100%;

        _:-ms-fullscreen, :root & { // for IE11 only
          width: calc(100% + 2 * 16px);
        }
      }
      > .layout {
        flex-wrap: wrap;

        figure {
          margin-right: 0 !important; // override .mr-4
          margin-bottom: 32px;
        }
      }
    }
  }
  @media $breakpoints.desktop {
    .upload {
      width: 75%;
    }
    .visualize {
      .ColorList {
        width: 370px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
