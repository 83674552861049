<template>
  <div
    class="ColorGrid"
    :class="{ dense }"
  >
    <v-card
      v-for="color in colors"
      :key="color.code"
      hover
      ripple
      flat
      tile
      color-group
      class="ColorGridCard bordered"
      :style="{ background: color.hex }"
      @click="handleClick(color)"
    >
      <v-responsive
        aspect-ratio="1.5"
        class="layout align-end"
      >
        <v-card-text class="white py-1 px-3">
          <div class="ColorName text-truncate">
            <strong>{{ $t_locale(color, 'name') }}</strong>
          </div>
          <div class="ColorCode text-truncate">
            {{ color.code }}
          </div>
        </v-card-text>
      </v-responsive>
    </v-card>
    <div
      v-if="!colors.length"
      class="empty"
    >
      <slot name="empty">
        <v-layout align-center>
          <v-icon class="grey--text mr-2">
            block
          </v-icon>
          <em class="grey--text">{{ $t('colorPicker.filters.search.noResults') }}</em>
        </v-layout>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ColorGrid',
  props: {
    colors: Array,
    dense: Boolean,
    colorGroup: String,
  },
  methods: {
    handleClick(color) {
      this.$emit('select', color);
      const colorGroupLabel = this.$props.colorGroup || `${color.code} - ${color.name}`;
      const colorGroupTrigger = this.$props.colorGroup;
      let analyticsLabel = colorGroupLabel || '';
      let analyticsAction = 'Colour Swatch';
      if (colorGroupTrigger) {
        analyticsAction = this.$route.meta.title() !== 'Colour finder'
          ? 'Paint Details Panel'
          : 'Visualize';
        analyticsLabel = `${colorGroupLabel} - ${color.code} - ${color.name}`;
      }
      this.$gtag.event({
        eventCategory: this.$route.meta.title(),
        eventAction: analyticsAction,
        eventLabel: analyticsLabel,
      });
    },
  },
};
</script>

<style lang="styl">
dense() {
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  grid-gap: 16px;

  .v-card {
    .v-card__text {
      display: none;
    }
  }

  // IE11 fixes (doesn't support CSS grid)
  _:-ms-fullscreen, :root & {
    margin: -(16px / 2);

    .v-card {
      width: 75px;
      margin: (16px / 2);
    }
  }
}

.ColorGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 32px;

  .v-card {
    .v-responsive__content {
      // prevent overflow
      flex-shrink: 1;
      width: 100%;
    }
    .v-card__text {
      opacity: 0;
      transition: opacity 300ms;
    }
    &:hover {
      .v-card__text {
        opacity: 1;
      }
    }
  }

  .empty {
    grid-column: 1 / none;
  }

  &.touching {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: 0;

    .v-card {
      transition: transform 300ms;
      // overriding vuetify-native dropdown to use custom (lightened) one
      box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
      border-width: 0 !important; // override !important in base .bordered class

      &:hover {
        z-index: 1;
        transform: scale(1.1);
      }
    }
  }

  // IE11 fixes (doesn't support CSS grid)
  _:-ms-fullscreen, :root & {
    display: flex;
    flex-wrap: wrap;
    margin: -(32px / 2);

    .v-card {
      width: 100px;
      margin: (32px / 2);
    }

    &.touching {
      margin-left: 0;
      margin-right: 0;

      .v-card {
        width: 150px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &.dense {
    dense();
  }
  @media (max-width: 599px) {
    dense();
  }
}
</style>
