// IE11 polyfills
import '@babel/polyfill';
import 'whatwg-fetch';

// Types
import { ColorRGB, ColorHSV } from '@renoworks/color';
export interface Family {
  color: string;
  hsvRanges: [[number, number], [number, number], [number, number]];
  dark?: boolean;
}
export interface Category {
  name: string;
  name_fr?: string;
  id?: string;
  categories?: Category[];
}
export interface Color {
  name: string;
  name_fr?: string;
  code: string;
  filters: string[];
  hex: string;
  rgb: ColorRGB;
  hsv: ColorHSV;
}
export const colorDecoder = ({ rgb }) => rgb;

// Imports
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/main.styl';
import App from '@/App.vue';
import router from '@/router';
import store, { analyticsGA4ID } from '@/store';
import i18n from '@/i18n';
import DOMAINS from '@/domains.json';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

analyticsGA4ID.promise.then((trackingID) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: trackingID,
      },
      debug: {
        enabled: process.env.NODE_ENV !== 'production',
        sendHitTask: process.env.NODE_ENV !== 'development',
      },
    },
    router,
  );
}).catch((error) => {
  console.error('Error resolving tracking ID:', error);
});

Vue.use(Vuetify, {
  theme: store.state.config.theme, // NB: will be overwritten immediately, so just for initial paint
});

Vue.prototype.$t_locale = (obj, key) => {
  // custom messages mapper to get 'name_fr'-style translations out of color dataset
  return obj[`${key}_${i18n.locale}`] || obj[key];
};

export const tenant = (() => {
  // [<tenant>.]color-explorer[-<env>].renoworks.com|?tenant=<tenant>
  // e.g. localhost:8080/?tenant=beautitone
  // e.g. beautitone.color-explorer-test.renoworks.com
  // w.g. custom.tld + domains.json { domain: <tenant> }
  const { search, hostname } = window.location;
  const urlParams = new URLSearchParams(search);
  const m = hostname.match(/^((.+?)\.)?color-explorer/) || [];
  const tenant = DOMAINS[hostname] || urlParams.get('tenant') || m[2] || 'default';

  return tenant;
})();

new Vue({
  router,
  store,
  i18n,
  data: () => ({ tenant }),
  render: h => h(App),
}).$mount('#app');
